export enum CmsPages {
  HOME_PAGE = 'HomePage',
  PRODUCT_DETAILS_PAGE = 'ProductDetailsPage',
  PRODUCT_RESULTS_PAGE = 'ProductResultsPage',
  PRODUCT_FLIGHTS_PAGE = 'ProductFlightsPage',
  REVIEW_PAGE = 'ReviewPage',
  RESORTS_PAGE = 'ResortsPage',
  GUESTS_PAGE = 'GuestsPage',
  PAYMENT_PAGE = 'PaymentPage',
  HELP_PAGE = 'HelpPage',
  HELP_CHARITIES_PAGE = 'HelpCharitiesPage',
  CONFIRMATION_PAGE = 'ConfirmationPage',
  TOURS_RESULTS_PAGE = 'ToursResultsPage',
  ALL_DESTINATIONS_PAGE = 'AllDestinationsPage',
  OFFERS_PAGE = 'SpecialOffers',
  MY_BOOKING_LOGIN_PAGE = 'MyBookingLoginPage',
  MY_BOOKING_PAGE = 'MyBookingPage',
  TRAVEL_DISCLAIMER_PAGE = 'TeavelDisclaimerPage',
  AGENTS_LOGIN_PAGE = 'AgentsLoginPage',
  AGENTS_RESET_PASSWORD_PAGE = 'AgentsResetPasswordPage',
  AGENTS_HOME_PAGE = 'AgentsHomePage',
  AGENTS_NEW_PASSWORD = 'AgentsNewPassword',
  AGENTS_SOCIAL_MEDIA_POST_PAGE = 'AgentSocialMediaPostPage',
  SITEMAP_HOME = 'SitemapHome',
  MAIL_TRAVEL = 'mail-travel',
  NO_SINGLE_SUPPLEMENT_HOLIDAYS = 'no-single-supplement',
  SECOND_WEEK_FREE_HOLIDAYS = '2nd-week-free',
  THIRD_WEEK_FREE_HOLIDAYS = '3rd-week-free',
  ESCORTED_TOURS = 'escorted-tours',
  ABOUT_US = 'about-us',
  ABTA_ATOL = 'abta-and-atol',
  AIRPORT_EXTRAS = 'airport-extras',
  CHARITIES = 'charities',
  CONTACT_US = 'contact-us',
  EARLY_HOLIDAY_BOOKING = 'early-holiday-booking',
  HOW_TO_BOOK = 'how-to-book',
  INSURANCE = 'insurance',
  ONLINE_BROCHURES = 'online-brochures',
  ORDER_BROCHURE = 'order-a-brochure',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  CAR_PROTECTION = 'car-protection',
  INTERNATIONAL_PASSENGER_PROTECTION = 'international-passenger-protection'
}
