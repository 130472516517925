export const TestId = {
  agents: {
    nav: 'mh-agents-nav',
    tools: 'mh-agents-tools',
    lockedOut: 'mh-agents-locked-out',
    loginPage: 'mh-agents-login',
    resetPassword: 'mh-agents-reset-password',
    booking: 'mh-agents-booking',
    validation: {
      container: 'mh-agents-validation-container',
      icon: 'mh-agents-validation-icon'
    },
    bookings: {
      container: 'mh-agents-bookings-container',
      bookings: 'mh-agents-bookings-bookings'
    },
    teamMember: {
      container: 'mh-agents-team-member-container',
      area: 'mh-agents-team-member-area',
      telephone: 'mh-agents-team-member-telephone',
      email: 'mh-agents-team-member-email'
    }
  },
  allInclusive: {
    destinations: 'all-inclusive-destinations'
  },
  list: {
    base: 'mh-list-base',
    listItem: 'mh-list-item',
    title: 'mh-list-title'
  },
  spinner: 'mh-spinner',
  infoCard: {
    base: 'mh-infoCard-base'
  },
  hero: 'mh-hero',
  heroHeaderGradient: 'mh-her-header-gradient',
  testimonialHero: 'mh-testimonial-hero',
  badge: 'mh-badge',
  tooltip: 'mh-tooltip',
  testimonial: {
    carousel: 'mh-carousel',
    review: 'mh-review'
  },
  starRating: {
    main: 'mh-star-rating',
    active: 'mh-active-star',
    inActive: 'mh-inactive-star'
  },
  imageCrossfade: {
    main: 'mh-image-crossfade',
    slideshowContainer: 'mh-slideshow-container',
    baseImage: 'mh-base-image',
    slideshowImage: 'mh-slideshow-image'
  },
  destination: {
    main: 'mh-destination',
    imageCrossfade: 'mh-image-crossfade',
    titleLabel: 'mh-title-lable',
    attributeLabel: 'mh-attribute-label',
    attributeDescriptionLabel: 'mh-attribute-description-label'
  },
  destinationsGroup: 'mh-destinations',
  productPackage: 'mh-product-package',
  productPackageGroup: {
    main: 'mh-product-package-group',
    cta: 'nh-product-package-group-cta'
  },
  destinationLink: 'mh-destination-link',
  tourLink: 'mh-tour-link',
  benefits: 'mh-benefits',
  benefitsRow: 'mh-benefits-row',
  benefitsGrid: 'mh-benefits-grid',
  benefitsSidebar: 'mh-benefits-sidebar',
  staticBenefits: 'mh-static-benefits',
  icon: 'mh-Icon',
  button: 'mh-button',
  mobileNavigation: {
    main: 'mh-mobile-navigation',
    drawerEven: 'mh-nav-drawer-even',
    drawerOdd: 'mh-nav-drawer-odd',
    currentNavLevelHeading: 'mh-nav-current-level-heading',
    contentHeading: 'mh-nav-content-heading',
    contentContainer: 'mh-nav-content-container',
    destinationRow: 'mh-nav-country-row',
    navigationRow: 'mh-nav-row',
    navigationRowLabel: 'mh-nav-row-label',
    backRow: 'mh-back-row',
    backRowLabel: 'mg-back-row-label',
    topRow: 'mh-top-row',
    closeButton: 'mh-close-button',
    bottomNumber: 'mh-nav-bottom-number'
  },
  counter: {
    incrementButton: 'mh-increment-button',
    decrementButton: 'mh-decrement-button',
    increment: 'mh-icon-increment',
    decrement: 'mh-icon-decrement',
    main: 'mh-counter'
  },
  destinationHero: 'mh-destination-hero',
  homeHero: 'mh-home-hero',
  noOptionMessage: 'mh-no-option-message',
  option: 'mh-option',
  searchContainer: 'mh-search-container',
  guestInput: 'mh-guest-input',
  guestInputContainer: {
    adults: 'mh-guest-input-container-adults-counter',
    children: 'mh-guest-input-container-children-counter',
    dropdown: 'mh-guest-input-container-dropdown'
  },
  rooms: {
    dobContainer: 'mh-rooms-dob-container'
  },
  navBar: {
    main: 'mh-nav-bar',
    desktopNavContainer: 'mh-navbar-nav-container',
    desktopNavItem: 'mh-navbar-item',
    desktopNavItemHelp: 'mh-navbar-item-help',
    basket: 'mh-navbar-basket',
    logo: 'mh-navbar-logo',
    hamburger: 'mh-navbar-hamburger',
    filtersBarDrawerTriggerButton: 'mh-filters-bar-drawer-trigger-button',
    openingTimes: 'mh-opening-times'
  },
  desktopNavigation: {
    main: 'mh-desktop-navigation',
    navItemArrow: 'mh-desktop-nav-arrow',
    navOverlay: 'mh-desktop-nav-overlay',
    navContentContainer: 'mh-desktop-nav-content-container',
    navTabs: 'mh-desktop-nav-tabs',
    navTab: 'mh-desktop-nav-tab'
  },
  desktopNavigationContent: {
    main: 'mh-desktop-nav-content-container'
  },
  destinationsNavContent: {
    main: 'mh-destinations-nav-content-container',
    tripTypesColumn: 'mh-trip-types-container',
    tripTypesHeading: 'mh-trip-types-heading',
    filterItem: 'mh-nav-filter-item',
    destinationsContentContainer: 'mh-destinations-content-container',
    sortBar: 'mh-sort-bar',
    sortHeading: 'mh-sort-bar-heading',
    sortOptionsContainer: 'mh-sort-options-container',
    sortOption: 'mh-sort-option',
    sortAdditionalOptions: 'mh-sort-additional-options',
    sortAdditionalOptionsSelect: 'mh-sort-additional-options-select',
    sortAdditionalOptionsSelectItem: 'mh-sort-additional-options-select-item',
    additionalSortLabel: 'mh-sort-additional-label',
    destinationsListContainer: 'mh-destinations-list-container',
    destinationsItem: 'mh-destinations-country-item'
  },
  guestCountTitle: 'mh-guest-count-title',
  guestCounterContainer: 'mh-guest-counter-container',
  dob: {
    container: 'mh-dob-container',
    rows: 'mh-dob-rows',
    row: 'mh-dob-row',
    ageLabel: 'mh-dob-age-label',
    input: 'mh-dob-input',
    day: 'mh-dob-input-day',
    month: 'mh-dob-input-month',
    year: 'mh-dob-input-year'
  },
  destinationSearch: {
    typo: 'mh-dest-search-typo',
    typoItems: 'mh-dest-search-typo-items'
  },
  airportSearch: {
    dropdown: 'mh-airport-dropdown',
    noResults: 'mh-airport-search-no-results',
    filterApplied: 'mh-airport-search-filter-applied',
    title: 'mh-title',
    input: {
      container: 'mh-airport-input--container',
      clearIcon: 'mh-airport-input--icon'
    },
    checkbox: {
      groupLabel: 'mh-airport-checkbox-group-label',
      label: 'mh-airport-checkbox-label',
      group: 'mh-airport-checkbox-group',
      on: 'mh-airport-checkbox-on',
      off: 'mh-airport-checkbox-off'
    }
  },
  dates: {
    search: 'mh-dates-search',
    title: 'mh-dates-title',
    info: 'mh-dates-info',
    clearDatesLabel: 'mh-dates-clear-dates-label',
    noNightsLabel: 'mh-dates-no-nights-label'
  },
  multiselectFilters: {
    main: 'mh-multiselect-filters',
    header: 'mh-multiselect-filters-header',
    filterRowVisible: 'mh-multiselect-filter-row-visible',
    filterRowHidden: 'mh-multiselect-filter-row-hidden',
    filterRowAll: 'mh-multiselect-filter-row-all',
    showMoreFilters: 'mh-show-more-filters',
    textFilterLabel: 'mh-text-filter-label',
    starRatingFilterLabel: 'mh-star-rating-filter-label',
    tripAdvisorFilterLabel: 'mh-trip-advisor-filter-label',
    numberOfItemsLabel: 'mh-filter-number-of-items-label',
    filterCheckbox: 'mh-filter-checkbox',
    checkboxFilterType: 'mh-checkbox-filter-type',
    starRatingFilterType: 'mh-star-rating-filter-type'
  },
  singleSelectFilters: {
    main: 'mh-single-select-filters',
    filterRadio: 'mh-single-select-filter-radio',
    header: 'mh-single-select-filters-header',
    filterRowVisible: 'mh-single-select-filter-row-visible',
    filterRowHidden: 'mh-single-select-filter-row-hidden',
    filterRowAll: 'mh-single-select-filter-row-all',
    showMoreFilters: 'mh-show-more-filters',
    textFilterLabel: 'mh-text-filter-label',
    starRatingFilterLabel: 'mh-star-rating-filter-label',
    tripAdvisorFilterLabel: 'mh-trip-advisor-filter-label',
    numberOfItemsLabel: 'mh-filter-number-of-items-label'
  },
  filtersSideBar: {
    main: 'mh-filters-side-bar',
    multiSelectFilter: 'mh-multi-select-filter',
    singleSelectFilter: 'mh-single-select-filter'
  },
  filters: {
    sortBy: 'mh-filters-sort-by',
    sortBySelect: 'mh-filters-sort-by-select',
    sortByMenuItem: 'mh-filters-sort-by-menu-item',
    priceRange: 'mh-filters-price-range',
    priceRangeMinPrice: 'mh-filters-price-range-min-price',
    priceRangeMaxPrice: 'mh-filters-price-range-max-price',
    priceRangePreselect: 'mh-filters-price-range-preselect',
    priceRangePreselectGroup: 'mh-filters-price-range-preselect-group',
    rangeSlider: {
      main: 'mh-filters-range-slider',
      heading: 'mh-filters-range-heading',
      preselect: 'mh-filters-range-preselect'
    },
    toggleFilter: 'mh-filters-toggle',
    filtersMobileDrawer: 'mh-filters-mobile-drawer',
    filtersApplyButton: 'mh-filters-confirm-button'
  },
  productHero: {
    main: 'mh-product-hero',
    photoGalleryBadge: 'mh-photo-gallery-badge'
  },
  breadcrumb: {
    main: 'mh-breadcrumb',
    breadcrumbItem: 'mh-breadcrumb-item',
    breadcrumbCurrentItem: 'mh-breadcrumb-current-item',
    breadcrumbLink: 'mh-breadcrumb-link'
  },
  imageGallery: {
    main: 'mh-image-gallery',
    closeButton: 'mh-image-galley-close-button'
  },
  productDescription: {
    main: 'mh-product-description',
    title: 'mh-product-description-title',
    description: 'mh-product-description-description',
    descriptionMore: 'mh-product-description-description-more',
    starRating: 'mh-product-description-star-rating',
    tripAdvisorRating: 'mh-product-description-ta-rating',
    readMoreToggle: 'mh-product-description-readmore-trigger',
    placeLabel: 'mh-product-description-place-label',
    resortLabel: 'mh-product-description-resort-label'
  },
  hotelInformation: {
    main: 'mh-hotel-information'
  },
  modal: {
    main: 'mh-modal',
    closeButton: 'mh-modal-close-button',
    alert: {
      container: 'mh-modal-alert-container',
      title: 'mh-modal-alert-title',
      text: 'mh-modal-alert-text'
    },
    infoCardModal: {
      main: 'mh-info-card-modal'
    },
    validationErrorModal: 'mh-validation-error-modal',
    privateTourEnquiryModal: 'mh-private-tour-enquiry-modal',
    fullscreen: 'mh-modal-fullscreen',
    offerTypes: 'mh-modal-offer-types',
    basket: 'mhi-search-error',
    earlyFlightsModal: 'mhi-modal-early-flights'
  },
  productFacilities: {
    main: 'mh-product-facilities',
    facilityListItem: 'mh-product-facility-list-item',
    viewAllFacilities: 'mh-product-facilities-view-all',
    sectionTitile: 'mh-product-facilities-section-title',
    errataContainer: 'mh-product-facilities-errata-container',
    errataItem: 'mh-product-facilities-errata-item'
  },
  roomTypes: {
    roomTypeTile: {
      main: 'mh-room-type-tile',
      title: 'mh-room-type-title',
      moreDetailsTrigger: 'mh-room-type-more-details-trigger',
      boardBasisContainer: 'mh-room-type-board-basis-container',
      boardBasisRow: 'mh-room-type-board-basis-row',
      boardBasisRowLabel: 'mh-room-type-board-basis-row-label',
      boardBasisRowPrice: 'mh-room-type-board-basis-row-price'
    },
    bedBanksRoomTypeTile: {
      main: 'mh-bb-room-type-tile',
      title: 'mh-bb-room-type-title-title',
      titleDesktop: 'mh-bb-room-type-title-desktop',
      titleMobile: 'mh-bb-room-type-title-mobile',
      moreDetailsTrigger: 'mh-bb-room-type-more-details-trigger',
      boardBasisContainer: 'mh-bb-room-type-board-basis-container',
      boardBasisRow: 'mh-bb-room-type-board-basis-row',
      boardBasisRowLabel: 'mh-bb-room-type-board-basis-row-label',
      boardBasisRowPrice: 'mh-bb-room-type-board-basis-row-price',
      boardBasisOffer: 'mh-bb-offer',
      boardBasisOfferTooltip: 'mh-bb-offer-tooltip'
    },
    roomTypesGroup: {
      main: 'mh-room-types-group',
      readMore: 'mh-room-types-read-more',
      collapse: 'mh-room-types-collapse'
    }
  },
  description: {
    main: 'mh-generic-description',
    readMoreToggle: 'mh-generic-description-read-more-trigger',
    readMoreWrapper: 'mh-generic-description-read-more-wrapper'
  },
  specialOffers: {
    main: 'mh-special-offers'
  },
  localAreaInfo: {
    main: 'mh-local-area-info'
  },
  map: {
    main: 'mh-map',
    mapBox: 'mh-mapbox'
  },
  productReview: {
    main: 'mh-product-review',
    user: 'mh-product-user'
  },
  productReviewsGroup: {
    main: 'mh-product-reviews-group',
    readMoreReviewsTrigger: 'mh-product-reviews-group-read-more-trigger'
  },
  sectionTitle: {
    main: 'mh-section-title',
    heading: 'mh-section-title-heading',
    subHeading: 'mh-section-sub-heading'
  },
  pageTitle: {
    main: 'mh-page-title',
    count: 'mh-page-title-count'
  },
  cmsComponents: {
    main: 'mh-page-cms-components'
  },
  contentTabs: {
    main: 'mh-generic-content-tabs',
    button: 'mh-generic-content-tabs-button'
  },
  roomTypeTabs: {
    main: 'mh-product-room-type-tabs'
  },
  climate: 'mh-climate',
  infoTile: {
    main: 'mh-info-tile',
    icon: 'mh-info-tile-icon',
    title: 'mh-info-tile-title',
    description: 'mh-info-tile-description'
  },
  exploreResort: {
    exploreResortGroup: {
      main: 'mh-explore-resort-group'
    },
    exploreResortTile: {
      main: 'mh-explore-resort-tile',
      label: 'mh-explore-resort-tile-label',
      hotelsCount: 'mh-explore-resort-tile-hotels-count'
    }
  },
  noResults: {
    container: 'no-results'
  },
  flights: {
    flightResultRow: {
      main: 'mh-flight-result-row',
      pricing: 'mh-flight-result-row-pricing',
      price: 'mh-flight-result-row-price'
    },
    flightTimesRow: {
      main: 'mh-flight-times-row',
      multiLeg: 'mh-flight-times-row-multi-leg'
    },
    flightSort: 'mh-flight-sort',
    flightsTitle: 'mh-flights-title',
    flightsSideBar: 'mh-flights-side-bar',
    flightResults: 'mh-flight-results',
    flightNoResults: 'mh-flight-no-results'
  },
  panel: 'mh-panel',
  searchResults: {
    noResults: 'mh-search-results-no-results',
    loading: 'mh-search-results-loading',
    title: 'mh-search-results-title',
    singleResult: 'mh-search-single-result',
    noResultsHotelAvailability: {
      noPackages: 'mh-search-no-results-hotel-availability-no-packages',
      onePackage: 'mh-search-no-results-hotel-availability-one-package'
    }
  },
  review: {
    packageOverview: {
      main: 'mh-review-package-overview'
    },
    transfers: {
      main: 'mh-review-transfers',
      transferOptionItem: 'mh-review-transfers-transfer-option-item',
      transferPrice: 'mh-review-transfers-transfer-price',
      viewAllTransfersTrigger: 'mh-review-transfers-view-all',
      selectedPrice: 'mh-review-transfers-selected-price',
      fixedTransfers: 'mh-review-transfers-fixed-transfers'
    },
    baggage: {
      main: 'mh-review-baggage',
      includedSection: 'mh-review-baggage-included',
      addBaggageSection: 'mh-review-baggage-add-baggage',
      includedBaggageRow: 'mh-review-baggage-included-row',
      extraBaggageRow: 'mh-review-baggage-extra-row',
      extras: 'mh-review-baggage-extras',
      bagPrice: 'mh-review-bag-price'
    },
    specialOffers: {
      main: 'mh-review-special-offers',
      offerPill: 'mh-review-special-offers-offer-pill',
      upgradeCheckbox: 'mh-review-special-offers-upgrade-checkbox',
      upgradeRadio: 'mh-review-special-offers-upgrade-radio',
      noOffers: 'mh-review-special-offers-no-results'
    },
    specialOfferTabs: {
      main: 'mh-special-offer-tabs'
    },
    accommodation: {
      main: 'mh-review-accommodation',
      roomDetails: 'mh-review-accommodation-room-details',
      checkInOutDetails: 'mh-review-accommodation-check-in-out-details',
      changeRoomButton: 'mh-review-accommodation-change-room-button'
    },
    accommodationRequests: {
      main: 'mh-review-accommodation-requests',
      addRequestButton: 'mh-review-accommodation-requests-add-request-button',
      addOtherRequestButton: 'mh-review-accommodation-requests-add-other-request-button',
      addOtherRequestCheckbox: 'mh-review-accommodation-requests-add-other-request-checkbox',
      otherRequestsTextarea: 'mh-review-accommodation-requests-textarea',
      otherRequestRow: 'mh-review-accommodation-requests-other-requests-row',
      addedRequestRow: 'mh-review-accommodation-requests-added-request-row'
    },
    pricePanel: {
      main: 'mh-review-price-panel',
      priceRow: 'mh-review-price-panel-row',
      atolFee: 'mh-review-price-atol-fee',
      priceRowCondensed: 'mh-review-price-panel-row-condensed'
    },
    flights: {
      main: 'mh-review-flights',
      destinationInfo: 'mh-review-flights-destination-info',
      airlineLogo: 'mh-review-flights-airline-logo',
      flightDetails: 'mh-review-flight-details',
      title: 'mh-review-flight-title'
    },
    errata: {
      main: 'mh-review-errata',
      categoryLabel: 'mh-review-errata-category-label',
      errataText: 'mh-review-errata-text',
      title: 'mh-review-errata-title'
    }
  },
  checkboxRow: 'mh-chckbox-row',
  dropdown: {
    main: 'mh-generic-dropdown',
    dropdownElement: 'mh-generic-dropdown-element',
    option: 'mh-generic-dropdown-option'
  },
  checkbox: {
    main: 'mh-generic-checkbox',
    label: 'mh-generic-checkbox-label',
    formControl: 'mh-generic-checkbox-form-control'
  },
  stepper: {
    main: 'mh-generic-stepper',
    mobileBackButton: 'mh-generic-stepper-mobile-back-button',
    step: 'mh-generic-stepper-step',
    stepLabel: 'mh-generic-stepper-step-label'
  },
  resortGroup: {
    group: 'mh-resort-group-group',
    panel: 'mh-resort-group-panel'
  },
  footer: {
    mainFooter: {
      main: 'mh-main-footer',
      logos: 'mh-main-footer-logos',
      linksContainer: 'mh-main-footer-links',
      contactContainer: 'mh-main-footer-contact'
    },
    checkoutFooter: {
      main: 'mh-checkout-footer'
    }
  },
  guestForm: {
    main: 'mh-guest-form',
    mobilityRequirements: 'mh-guest-form-mobility-requirements',
    mobilityRequirementsCollapse: 'mh-guest-form-mobility-requirements-collapse'
  },
  radio: {
    main: 'mh-radio',
    label: 'mh-radio-label',
    subLabel: 'mh-radio-sub-label',
    formControl: 'mh-radio-form-control'
  },
  formikTextField: {
    main: 'mh-formik-text-field',
    input: 'mh-formik-text-input'
  },
  formikDropdownField: {
    main: 'mh-formik-dropdown-field'
  },
  openingTimesTable: {
    main: 'mh-opening-times-table'
  },
  hereToHelpNavContent: {
    main: 'mh-here-to-help-nav-content'
  },
  offersNavContent: {
    main: 'mh-offers-nav-content'
  },
  helpTileGroup: 'mh-help-tile-group',
  whatHappensNext: {
    main: 'mh-what-happens-next-component'
  },
  nextSteps: {
    main: 'mh-next-steps-component'
  },
  infoRow: {
    main: 'mh-info-row-component'
  },
  orderBrochureForm: {
    main: 'mh-order-brochure-form-component'
  },
  guides: {
    tile: 'mh-guide-tile-component',
    sideBar: 'mh-guide-side-bar',
    groupComponent: 'mh-guide-group-component'
  },
  contactDetails: {
    main: 'mh-contact-details-component'
  },
  mediaEnquiries: {
    main: 'mh-media-enquiries-component'
  },
  contactForms: {
    formGroup: 'mh-contact-form-group-component',
    requestQuoteForm: 'mh-request-a-quote-form-component',
    submitConfirmation: 'mh-form-submit-confirmation',
    formContent: 'mh-form-content-container'
  },
  formikTextArea: {
    main: 'mh-formik-textarea-component'
  },
  formikDatePickerField: {
    main: 'mh-formik-date-picker-field-component'
  },
  formikDestinationField: {
    main: 'mh-formik-destination-field-component'
  },
  formikAirportsField: {
    main: 'mh-formik-airports-field-component'
  },
  holidaySummary: {
    main: 'mh-holiday-summary-component'
  },
  itinerary: {
    title: 'mh-itinerary-title',
    item: {
      subtitle: 'mh-itinerary-item-subtitle',
      departIcon: 'mh-itinerary-item-depart-icon',
      departText: 'mh-itinerary-item-depart-text',
      meals: 'mh-itinerary-item-meals'
    }
  },
  timeline: {
    container: 'mh-time-line-container',
    readMore: 'mh-time-line-read-more'
  },
  card: {
    base: 'mh-card-base',
    room: {
      body: 'mh-card-room-body',
      adjustment: 'mh-card-room-adjustment'
    },
    tourExtension: {
      days: 'mh-card-tour-extension-days'
    },
    accommodation: {
      container: 'mh-card-accommodation-container',
      moreDetails: 'mh-card-accommodation-moreDetails'
    }
  },
  tourProductGroup: {
    cta: 'mh-tour-product-group-cta'
  },
  tourProductTile: {
    main: 'mh-tour-product-tile',
    group: 'mh-tour-product-group',
    infoCard: 'mh-tour-product-tile-info-card',
    tourName: 'mh-tour-product-tile-tour-name',
    tourLength: 'mh-tour-product-tile-tour-length',
    tourPrice: 'mh-tour-product-tile-tour-price'
  },
  experienceProductTile: {
    group: 'mh-experience-group',
    container: 'mh-experience-tile',
    infoCard: 'mh-experience-tile-info-card',
    tourName: 'mh-experience-tile-tour-name',
    tourLength: 'mh-experience-tile-tour-length',
    tourPrice: 'mh-experience-tile-tour-price',
    cta: 'mh-experience-tile-cta'
  },
  tourProductDescription: {
    main: 'mh-tour-product-description',
    title: 'mh-tour-product-description-title',
    subTitle: 'mh-tour-product-description-subtitle',
    locationLabel: 'mh-tour-product-description-location',
    allMealsIncludedLabel: 'mh-tour-product-description-all-meals-included',
    paceLabel: 'mh-tour-product-description-pace',
    mealsCountLabel: 'mh-tour-product-description-mealsCount',
    excursionsCountLabel: 'mh-tour-product-description-excursionsCountLabel'
  },
  basketViewer: {
    showPriceChange: 'mh-basket-viewer-show-price-change',
    summary: {
      container: 'mh-basket-viewer-summary-container',
      cta: 'mh-basket-viewer-summary-cta',
      title: 'mh-basket-viewer-summary-title'
    },
    upgradesSummary: {
      container: 'mh-basket-viewer-upgrades-summary-cta'
    },
    price: {
      container: 'mh-basket-viewer-price-container',
      amount: 'mh-basket-viewer-price-amount'
    },
    paymentPlan: 'mh-basket-viewer-payment-plan',
    extras: {
      transfers: 'mh-basket-viewer-extras-transfers',
      luggage: 'mh-basket-viewer-extras-luggage',
      includedLuggage: 'mh-basket-viewer-extras-included-luggage',
      supplement: 'mh-basket-viewer-extras-supplement',
      noSupplement: 'mh-basket-viewer-extras-no-supplement',
      noLuggage: 'mh-basket-viewer-extras-no-luggage'
    },
    flights: {
      details: 'mh-basket-viewer-flights-details',
      outbound: 'mh-basket-viewer-flights-outbound',
      return: 'mh-basket-viewer-flights-return',
      ghost: 'mh-basket-viewer-flights-ghost'
    },
    hotel: {
      name: 'mh-basket-viewer-hotel-name',
      nights: 'mh-basket-viewer-hotel-nights',
      room: 'mh-basket-viewer-hotel-room'
    },
    booking: {
      offer: 'mh-basket-viewer-booking-offer',
      item: 'mh-basket-viewer-booking-item'
    }
  },
  basketPanel: 'mh-basket-panel',
  extendToursGroup: {
    main: 'mh-extend-tours-group-component',
    heading: 'mh-extend-tours-group-heading',
    card: 'mh-extend-tours-group-card'
  },
  tourInformation: {
    main: 'mh-tour-important-information'
  },
  tourPricing: {
    main: 'mh-tour-pricing-component',
    tabsWrapper: 'mh-tour-pricing-tabs-wrapper',
    tabsComponent: 'mh-tour-pricing-tabs-component',
    expandableAvailabilityMonth: 'mh-tour-pricing-expandable-month',
    availabilityRow: 'mh-tour-pricing-row',
    modal: 'mh-tour-pricing-modal'
  },
  accommodationList: {
    container: 'mh-accommodation-list-container',
    toggleView: 'mh-accommodation-list-toggle-view'
  },
  pageTabNavigation: {
    main: 'mh-page-tab-navigation-component',
    tab: 'mh-page-tab-navigation-component-tab'
  },
  tourMap: {
    main: 'mh-tour-map-component'
  },
  tourDossiers: {
    container: 'mh-tour-dossiers-container',
    document: 'mh-tour-dossiers-document'
  },
  tabSearch: {
    main: 'mh-tab-search-component',
    item: {
      selected: 'mh-tab-content-item-selected',
      notSelected: 'mh-tab-content-item-notSelected'
    }
  },
  tourReview: {
    main: 'mh-tour-review-component',
    group: 'mh-tour-review-group-component'
  },
  tourResultsGrid: {
    main: 'mh-tour-search-results-grid',
    resultTile: 'mh-tour-search-results-grid-tile'
  },
  recentlyViewed: {
    container: 'mh-recently-viewed-container',
    baseCard: 'mh-recently-viewed-base-card'
  },
  articleComponent: {
    main: 'mh-article-component'
  },
  linkCard: {
    main: 'mh-link-card-component'
  },
  genericDynamicComponent: {
    main: 'mh-generic-dynamic-component'
  },
  destinationsByContinent: {
    main: 'mh-destinations-by-continent-component'
  },
  cmsDestinationTile: {
    main: 'mh-cms-destination-tile-component'
  },
  loadingOverlay: 'mh-loading-overlay-container',
  postcode: {
    noOptions: 'mh-postcode-no-options'
  },
  linkListComponent: {
    main: 'mh-link-list-component'
  },
  genericLinkListComponent: {
    main: 'mh-generic-link-list-component'
  },
  requiredPayments: {
    container: 'mh-required-payments-container'
  },
  searchDestinationsListDropdown: {
    main: 'mh-search-destinations-list-dropdown',
    item: 'mh-search-destinations-list-item'
  },
  popularHotels: {
    button: 'mh-popular-hotels-button'
  },
  homepageTripTypesComponent: {
    main: 'mh-homepage-trip-types'
  },
  tripTypesGrid: {
    main: 'mh-trip-types-grid-component',
    item: 'mh-trip-types-grid-item'
  },
  promoBanner: {
    main: 'mh-promo-banner-component'
  },
  vesselDescription: {
    main: 'mh-vessel-description-component',
    group: 'mh-vessel-description-group-component'
  },
  countryFlag: {
    main: 'mh-country-flag-component'
  },
  cookiesBanner: {
    main: 'mh-cookies-banner-component'
  },
  newsletterSignUpForm: {
    main: 'mh-newsletter-sign-up-form'
  },
  lazyLoad: {
    backgroundImage: 'mh-lazy-load-background-image-component',
    image: 'mh-lazy-load-image-component'
  },
  priceCalendar: {
    container: 'mh-price-calendar-container',
    filters: 'mh-price-calendar-filters',
    airports: {
      main: 'mh-price-calendar-airports-main',
      tooltip: 'mh-price-calendar-airports-tooltip'
    },
    calendar: 'mh-price-calendar-calendar',
    offer: 'mh-price-calendar-offer',
    day: 'mh-price-calendar-day',
    price: {
      selected: 'mh-price-calendar-price-selected',
      notSelected: 'mh-price-calendar-price-notSelected'
    },
    footer: {
      container: 'mh-price-calendar-footer-container',
      outbound: 'mh-price-calendar-footer-outbound',
      inbound: 'mh-price-calendar-footer-inbound'
    },
    legend: {
      container: 'mh-price-calendar-legend-container',
      key: 'mh-price-calendar-legend-key'
    },
    nav: {
      container: 'mh-price-calendar-nav-container'
    }
  },
  classicWebsiteLink: {
    main: 'mh-classic-website-link'
  },
  productOfferBadge: {
    main: 'mh-product-offer-badge'
  },
  dynamicOffersComponent: {
    main: 'mh-dynamic-offers-componenet',
    tile: 'mh-dynamic-offer-tile'
  },
  mmb: {
    balance: {
      container: 'mh-mmb-balance-container',
      title: 'mh-mmb-balance-title'
    },
    header: {
      container: 'mh-mmb-header-container'
    },
    actions: {
      container: 'mh-mmb-actions-container',
      action: 'mh-mmb-actions-action'
    },
    transfers: {
      container: 'mh-mmb-transfers-container',
      cta: 'mh-mmb-transfers-cta'
    },
    paymentBalance: {
      container: 'mh-mmb-payment-balance-container'
    },
    documentRow: {
      container: 'mh-mmb-document-row'
    },
    extras: {
      main: 'mh-mmb-extras-main',
      extraItem: 'mh-mmb-extra-item'
    },
    invoice: {
      main: 'mh-mmb-invoice'
    },
    travelDisclaimerLink: {
      main: 'mh-travel-disclaimer-link'
    }
  },
  pagination: {
    container: 'mh-pagination-container',
    ellipsis: 'mh-pagination-ellipsis',
    next: 'mh-pagination-next',
    previous: 'mh-pagination-previous',
    page: 'mh-pagination-page'
  },
  promoCode: {
    container: 'mh-promo-code-container',
    error: 'mh-promo-code-error'
  },
  creditPaymentPlan: {
    container: 'mh-credit-payment-plan-container'
  },
  skeletonLoading: {
    skeletonText: 'mh-skeleton-text'
  },
  trustpilotWidget: {
    main: 'mh-trustpilot-widget'
  },
  brochure: {
    orderModalText: 'mh-modal-alert-text',
    form: {
      container: 'mh-order-brochure-form-component',
      dropdown: 'mh-generic-dropdown',
      dropdownElem: 'mh-generic-dropdown-element',
      inputArea: 'mh-formik-text-field',
      nameInput: 'mh-formik-text-input'
    }
  },
  dealFinder: {
    search: {
      container: 'mh-deal-finder-search-container',
      bar: 'mh-deal-finder-search-bar',
      destinationList: 'mh-deal-finder-search-destination-list',
      toursDestinationList: 'mh-deal-finder-search-tours-destination-list',
      toursDestinations: 'mh-deal-finder-search-tours-destinations',
      value: 'mh-deal-finder-search-value'
    },
    filters: {
      container: 'mh-deal-finder-filters-container',
      hasAppliedFilters: 'mh-deal-finder-filters-has-applied-filters',
      mealbases: 'mealbases',
      priceBand: 'priceband',
      durationRange: 'duration_range',
      tourTheme: 'tour_theme',
      tripType: 'trip_type',
      countries: 'countries',
      pace: 'pace'
    },
    results: {
      container: 'mh-deal-finder-results-container',
      loadMore: 'mh-deal-finder-results-load-moer'
    },
    tabs: 'mh-deal-finder-tabs',
    months: {
      pillContainer: 'mh-deal-finder-months-pill-container'
    },
    dayPicker: {
      container: 'mh-day-picker-container',
      body: 'mh-day-picker-body',
      month: 'mh-day-picker-month'
    },
    duration: 'mh-deal-finder-duration',
    pill: 'mh-deal-finder-pill',
    day: 'mh-deal-finder-day',
    airports: {
      heading: 'mh-deal-finder-airports-heading',
      option: 'mh-deal-finder-airports-option',
      list: 'mh-deal-finder-airports-list'
    },
    destination: {
      container: 'mh-deal-finder-destination-container',
      suggested: 'mh-deal-finder-destination-suggested',
      option: 'mh-deal-finder-destination-option',
      pill: 'mh-deal-finder-destination-pill',
      heading: 'mh-deal-finder-destination-heading',
      listOption: 'mh-deal-finder-destination-list-option'
    },
    tile: {
      container: 'mh-deal-finder-tile-container',
      contentContainer: 'mh-deal-finder-content-container',
      destination: 'mh-deal-finder-tile-destination',
      hotel: 'mh-deal-finder-tile-hotel',
      startDateAttribute: 'mh-deal-finder-tile-start-date-attribute',
      startDate: 'mh-deal-finder-tile-start-date',
      nights: 'mh-deal-finder-tile-nights',
      priceRow: 'mh-deal-finder-tile-price-row',
      mealPlan: 'mh-deal-finder-tile-meal-plan',
      leadInPrice: 'mh-deal-finder-tile-lead-in-price',
      noPrice: 'mh-deal-finder-tile-no-price',
      toolTip: 'mh-deal-finder-tile-tool-tip'
    }
  },
  tours: {
    search: {
      container: 'mh-tours-search-container',
      heading: 'mh-tours-search-heading'
    },
    extras: {
      tourDetails: { card: 'mh-tours-extras-tour-details-card' },
      luggageIncluded: 'mh-tours-extras-luggage-included'
    }
  },
  websiteComments: {
    name: 'mh-website-comments-name',
    comments: 'mh-website-comments',
    email: 'mh-website-email',
    submit: 'mh-website-submit'
  },
  generalEnquiry: {
    name: 'mh-general-enquiry-name',
    enquiry: 'mh-general-enquiry-enquiry',
    email: 'mh-general-enquiry-email',
    telephone: 'mh-general-enquiry-telephone',
    submit: 'mh-general-enquiry-submit'
  },
  bookingEnquiry: {
    name: 'mh-booking-enquiry-name',
    email: 'mh-booking-enquiry-email',
    telephone: 'mh-booking-enquiry-telephone',
    bookingRef: 'mh-booking-enquiry-booking-ref',
    enquiry: 'mh-booking-enquiry-enquiry',
    submit: 'mh-booking-enquiry-submit'
  },
  requestQuote: {
    container: '',
    name: 'mh-request-quote-name',
    email: 'mh-request-quote-email',
    telephone: 'mh-request-quote-telephone',
    enquiry: 'mh-request-quote-enquiry',
    submit: 'mh-request-quote-submit',
    adults: 'mh-request-quote-adults',
    children: 'mh-request-quote-children',
    infants: 'mh-request-quote-infants',
    destination: 'mh-request-quote-destination',
    holiday: 'mh-request-quote-holiday'
  },
  sitemap: {
    tours: {
      container: 'mh-sitemap-tours-container',
      link: 'mh-sitemap-tours-link'
    },
    tripTypes: {
      container: 'mh-sitemap-trip-types-container',
      link: 'mh-sitemap-trip-types-link'
    },
    destinations: {
      container: 'mh-sitemap-destinations-container',
      link: 'mh-sitemap-destinations-link'
    },
    resorts: {
      container: 'mh-sitemap-resorts-container',
      resort: 'mh-sitemap-resorts-resort',
      hotel: 'mh-sitemap-resorts-hotel'
    }
  },
  gridCarousel: {
    container: 'mh-grid-carousel'
  },
  helpCentre: {
    search: {
      main: 'mh-help-centre-search-main'
    },
    linkListGroup: {
      main: 'mh-help-centre-link-list-group-main'
    },
    breadcrumb: {
      main: 'mh-help-centre-breadcrumb-main'
    },
    categoryPage: {
      main: 'mh-help-category-page-layout'
    },
    articlePage: {
      main: 'mh-help-article-page-layout'
    }
  },
  contentLayout: 'mh-content-layout',
  optimize: {
    basketPanel: {
      button: 'view-prices-button'
    },
    sidebar: {
      main: 'mobile-price-bar'
    }
  },
  searchBar: {
    container: 'mh-deal-finder-search-container',
    inputArea: 'mh-deal-finder-search-bar',
    tab: 'mh-tab-search-component',
    dateSel: {
      inputArea: 'mh-day-picker-body',
      inputOption: 'mh-deal-finder-day'
    },
    guest: {
      roomCounterArea: 'mh-guest-input',
      increaseBtn: 'mh-increment-button',
      roomCount: 'mh-guest-counter-container',
      childrenCounterArea: 'mh-guest-input-container-children-counter',
      childrenCounterAdd: 'mh-increment-button',
      childrenDOB: 'mh-rooms-dob-container',
      childrenDOBDay: 'mh-dob-input-day',
      childrenDOBMonth: 'mh-dob-input-month',
      childrenDOBYear: 'mh-dob-input-year',
      childAge: 'mh-dob-age-label'
    },
    monthSel: {
      inputArea: 'mh-deal-finder-tabs',
      inputOption: 'mh-deal-finder-pill'
    },
    durationSel: {
      inputArea: 'mh-deal-finder-tabs',
      inputOption: 'mh-deal-finder-pill'
    },
    destination: {
      inputField: 'mh-deal-finder-destination-heading',
      options: 'mh-deal-finder-search-destination-list',
      option: 'mh-deal-finder-destination-option',
      optionCheckBox: 'mh-airport-checkbox-off',
      selectedOption: 'mh-deal-finder-destination-pill'
    },
    airport: {
      inputField: 'mh-deal-finder-airports-heading',
      option: 'mh-deal-finder-airports-option',
      optionCheckBox: 'mh-airport-checkbox-off',
      selectedOption: 'mh-deal-finder-destination-pill'
    }
  },
  tripAdvisor: {
    subratings: 'mh-trip-advisor-subratings'
  },
  shareLinks: {
    main: 'mh-share-links'
  },
  video: {
    main: 'mh-video-player'
  },
  mediaPost: {
    cardContainer: 'media-post-card-container',
    modalContainer: 'media-post-modal-container'
  },
  callToBook: {
    main: 'mh-call-to-book'
  },
  discover: {
    main: 'mh-discover-the-world'
  },
  facebookWrapper: {
    main: 'mh-facebook-wrapper'
  },
  transfers: {
    transferDetails: 'mh-transfer-details'
  }
};
