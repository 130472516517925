import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';

const Container = styled.div(({ theme }: ThemeProps) => ({
  minHeight: theme.custom.spacing.medium,
  width: '100%'
}));

export type JSXType = JSX.Element | undefined | false | null;

export interface ScrollComponentProps {
  children: JSXType | Array<JSXType>;
}

export const LazyLoadComponent = ({ children }: ScrollComponentProps) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setIsVisible(true);
    }
  };
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0
  };

  useEffect(() => {
    if (window.IntersectionObserver) {
      const observer = new window.IntersectionObserver(callbackFunction, options);
      if (componentRef !== null && componentRef?.current) {
        observer.observe(componentRef?.current);
      }
      return () => {
        if (componentRef?.current) {
          observer.unobserve(componentRef?.current);
        }
      };
    }
  }, [componentRef]);

  return <Container ref={componentRef}>{isVisible ? children : null}</Container>;
};
