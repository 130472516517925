import { DataEnvelope } from '@model/iceberg';
import { Image } from '@model/common';
import { FAQ, Link, OpeningTimes } from '@model/content/common';
import { BrandCodes } from '@model/config/brands';

export enum FooterTypes {
  MAIN = 'main',
  CHECKOUT = 'checkout'
}

export interface Footer {
  fieldType: string;
  faqs: Array<FAQ>;
  links: Array<Link>;
  openingTimes: OpeningTimes | null;
  phoneNumber: string;
  title: string;
  travelAdvice: string;
  type: FooterTypes;
  variant: BrandCodes;
  logo: Image;
}

export type FooterModel = DataEnvelope<Footer>;
