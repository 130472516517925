import styled from '@emotion/styled';
import { Button as MUIButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, forwardRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';
import { Localisation } from '@model/common';
import { getLabelText } from '@util/common';
import { SkeletonElement, SkeletonVariant } from '@components/common/skeleton-loading';
import { ThemeProps } from '@theme/base';

interface ButtonBaseProps extends ThemeProps {
  buttonSize: number;
}
const ButtonBase: any = styled.div(({ theme, buttonSize }: ButtonBaseProps) => ({
  ['.MuiButtonBase-root']: {
    fontFamily: 'inherit',
    height: buttonSize,
    width: '100%',
    color: theme.custom.colors.white,
    outline: 'none',
    ...(theme.custom.typography.paragraph as any),
    borderRadius: 2,
    textTransform: 'none',
    ':hover': {
      cursor: 'pointer'
    }
  },

  ['.MuiButton-label']: {
    lineHeight: '2rem'
  }
}));

interface ButtonContainedProps extends ButtonBaseProps {
  color: string;
}
const ButtonContained: any = styled(ButtonBase)(({ theme, color, buttonSize }: ButtonContainedProps) => ({
  height: buttonSize,
  ['.MuiButtonBase-root']: {
    backgroundColor: theme.custom.states.buttons[color].default,
    color: theme.custom.colors.white,
    border: 'none',
    ':hover': {
      backgroundColor: theme.custom.states.buttons[color].hover
    },
    ':active': {
      backgroundColor: theme.custom.states.buttons[color].hover
    },
    ':disabled': {
      backgroundColor: theme.custom.states.buttons[color].disabled,
      color: theme.custom.colors.white
    }
  }
}));

interface ButtonOutlineProps extends ThemeProps {
  color: string;
  backgroundColor: string;
}
const ButtonOutline: any = styled(ButtonBase)(({ theme, color, backgroundColor }: ButtonOutlineProps) => ({
  ['.MuiButtonBase-root']: {
    ['span']: {
      textTransform: 'none'
    },
    color: theme.custom.states.buttons[color].default,
    border: `2px solid ${theme.custom.states.buttons[color].default}`,
    backgroundColor: backgroundColor || 'transparent',
    ':hover': {
      backgroundColor: theme.custom.states.buttons[color].light,
      border: `2px solid ${theme.custom.states.buttons[color].default}`
    },
    ':active': {
      border: `2px solid ${theme.custom.states.buttons[color].hover}`
    },
    ':disabled': {
      color: theme.custom.states.buttons[color].disabled,
      border: `2px solid ${theme.custom.states.buttons[color].disabled}`
    }
  }
}));

export enum ButtonColor {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  TERTIARY = 'TERTIARY',
  QUATERNARY = 'QUATERNARY'
}

export enum ButtonVariant {
  CONTAINED = 'CONTAINED',
  OUTLINED = 'OUTLINED'
}

export enum ButtonSize {
  SMALL = 'SMALL',
  REGULAR = 'REGULAR',
  LARGE = 'LARGE',
  FLUID = 'FLUID'
}

export interface ButtonProps {
  label: string | Localisation;
  tabIndex?: number;
  onClick?: (event: any) => void;
  color?: ButtonColor;
  disabled?: boolean;
  variant?: ButtonVariant;
  className?: string;
  size?: ButtonSize;
  isGhosting?: boolean;
  backgroundColor?: string;
  testId?: string;
  ref?: any;
  endIcon?: JSX.Element;
  startIcon?: JSX.Element;
  type?: 'button' | 'submit' | 'reset';
  resetTime?: number;
  buttonId?: string;
}

type MUIVariant = 'contained' | 'outlined' | 'rounded-contained' | 'rounded-outlined';
type VariantMapping = { [key in ButtonVariant]: MUIVariant };
const variantMapping: VariantMapping = {
  [ButtonVariant.CONTAINED]: 'contained',
  [ButtonVariant.OUTLINED]: 'outlined'
};

type ColorMapping = { [key in ButtonColor]: string };
const colorMapping: ColorMapping = {
  [ButtonColor.PRIMARY]: 'primary',
  [ButtonColor.SECONDARY]: 'secondary',
  [ButtonColor.TERTIARY]: 'tertiary',
  [ButtonColor.QUATERNARY]: 'quaternary'
};

type SizeMapping = { [key in ButtonSize]: number | string };
const sizeMapping: SizeMapping = {
  [ButtonSize.SMALL]: 40,
  [ButtonSize.REGULAR]: 60,
  [ButtonSize.LARGE]: 80,
  [ButtonSize.FLUID]: '100%'
};

const variantComponentMapping = {
  [ButtonVariant.CONTAINED]: ButtonContained,
  [ButtonVariant.OUTLINED]: ButtonOutline
};

const useStyles = makeStyles(() => ({
  myIconSizeMedium: {
    '& > *:first-child': {
      fontSize: '1.4rem'
    }
  }
}));

export const Button: FC<ButtonProps> = forwardRef((props: ButtonProps, ref: any) => {
  const {
    variant,
    color,
    label,
    disabled,
    onClick,
    tabIndex,
    className,
    size,
    testId,
    backgroundColor,
    type,
    isGhosting,
    endIcon,
    startIcon,
    resetTime = 600,
    buttonId
  } = props;
  const [clicked, setClicked] = useState(false);
  useDebounce(
    () => {
      if (clicked) {
        setClicked(false);
      }
    },
    resetTime,
    [clicked]
  );
  const ButtonComponent = variantComponentMapping[variant || ButtonVariant.CONTAINED];
  const classes = useStyles();
  const t: any = useI18NextContext();
  const wrappedOnClick = (e) => {
    setClicked(true);
    if (typeof onClick === 'function' && !clicked) {
      onClick(e);
    }
  };
  if (isGhosting) {
    return (
      <ButtonComponent
        buttonSize={sizeMapping[size || ButtonSize.REGULAR]}
        color={colorMapping[color || ButtonColor.PRIMARY]}
        variant={variantMapping[variant || ButtonVariant.CONTAINED]}
        className={className}
        backgroundColor={backgroundColor}
        ref={ref}
      >
        <SkeletonElement variant={SkeletonVariant.RECTANGLE} width={'100%'} />
      </ButtonComponent>
    );
  }
  return (
    <ButtonComponent
      id={buttonId}
      buttonSize={sizeMapping[size || ButtonSize.REGULAR]}
      color={colorMapping[color || ButtonColor.PRIMARY]}
      variant={variantMapping[variant || ButtonVariant.CONTAINED]}
      className={className}
      backgroundColor={backgroundColor}
      ref={ref}
    >
      <MUIButton
        disabled={disabled}
        tabIndex={tabIndex}
        onClick={wrappedOnClick}
        data-testid={testId || TestId.button}
        type={type}
        endIcon={endIcon}
        startIcon={startIcon}
        classes={{ iconSizeMedium: classes.myIconSizeMedium }}
        suppressHydrationWarning
      >
        {getLabelText(label, t)}
      </MUIButton>
    </ButtonComponent>
  );
});

export const CtaButton = styled(Button)(({ theme }: ThemeProps) => ({
  marginTop: theme.custom.spacing.xxLarge / 2
}));
