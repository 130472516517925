import { HYDRATE } from 'next-redux-wrapper';
import { isEqual } from 'lodash';
import { TripTypes } from '@model/contentful';

export enum RecentlyViewedActions {
  ADD_RECENTLY_VIEWED = '@RECENTLY_VIEWED/ADD_RECENTLY_VIEWED'
}

export enum RecentlyViewedType {
  FIT = 'fit',
  TOURS = 'tours'
}

export interface RecentlyViewedItem {
  type: RecentlyViewedType;
  id: any;
  tripType: TripTypes;
}

export const INITIAL_RECENTLY_VIEWED_STATE: Array<RecentlyViewedItem> = [];

export const addRecentlyViewed = (payload: RecentlyViewedItem) => ({
  type: RecentlyViewedActions.ADD_RECENTLY_VIEWED,
  payload
});

export const recentlyViewedReducer = (
  state: Array<RecentlyViewedItem> = INITIAL_RECENTLY_VIEWED_STATE,
  action: any
) => {
  switch (action.type) {
    case HYDRATE:
      if (isEqual(state, INITIAL_RECENTLY_VIEWED_STATE)) {
        return action.payload.recentlyViewed || state;
      }
      return state;
    case RecentlyViewedActions.ADD_RECENTLY_VIEWED: {
      const oldItems: Array<RecentlyViewedItem> = state;
      const newItems: Array<RecentlyViewedItem> = [action.payload];
      if (oldItems.some((item: RecentlyViewedItem) => item.id === action.payload.id)) {
        return newItems.concat(
          oldItems.filter((entry) => {
            if (entry.id !== action.payload.id) {
              return true;
            }

            if (entry.id === action.payload.id && entry.tripType !== action.payload.tripType) {
              return true;
            }

            return false;
          })
        );
      }
      if (oldItems.length < 4) {
        return newItems.concat(oldItems);
      }
      return newItems.concat(oldItems).slice(0, 4);
    }
    default:
      return state;
  }
};
