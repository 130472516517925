import styled from '@emotion/styled';
import { BaseSpinner } from '@components/common/spinner';
import { mixins } from '@styles/mixins';
import { ThemeProps, CssThemeProps } from '@theme/base';

export const PillSmall: any = styled.div(({ theme }: ThemeProps) => ({
  width: 14,
  height: 18,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.custom.colors.group8.lighter,
  ...(theme.custom.typography.labels.small as any),
  padding: theme.custom.spacing.xSmall
}));

export const Pill: any = styled.div(({ theme, css }: CssThemeProps) => ({
  display: 'inline-block',
  padding: `2px ${theme.custom.spacing.xSmall}px`,
  minHeight: 22,
  borderRadius: 2,
  textAlign: 'left',
  fontSize: '1.2rem',
  backgroundColor: theme.custom.colors.group8.light,
  marginRight: theme.custom.spacing.large,

  ['&:last-of-type']: {
    marginRight: 0
  },
  ...css
}));

export const WarningBox = styled.div(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.medium,
  border: `1px solid ${theme.custom.colors.group8.light}`,
  backgroundColor: theme.custom.colors.group8.lighter,
  marginTop: theme.custom.spacing.xLarge,
  marginBottom: theme.custom.spacing.xLarge,
  ...(theme.custom.typography.paragraph as any)
}));

export const ReadMore: any = styled.a(({ theme }: ThemeProps) => ({
  ...mixins(theme).link,
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 500,
  paddingBottom: theme.custom.spacing.xSmall,
  marginTop: theme.custom.spacing.xLarge,
  display: 'block',
  textDecoration: 'none',

  ['span']: {
    textDecoration: 'underline'
  },
  ['i']: {
    fontSize: '1rem',
    marginLeft: theme.custom.spacing.xSmall
  },
  ['i, span']: {
    transition: `color ${theme.custom.transitions.default}`
  },
  ['&:hover']: {
    ['i, span']: {
      color: theme.custom.states.link.hover
    }
  }
}));

export const TileSpinner = styled(BaseSpinner)({
  position: 'absolute',
  left: '50%',
  top: '50%',
  marginTop: -25,
  marginLeft: -25
});

export const PlainLinkStyles = {
  cursor: 'pointer',
  textDecoration: 'none',
  ['&:hover,&:active']: {
    textDecoration: 'none'
  }
};

export const PlainLink = styled.span({
  cursor: 'pointer',
  textDecoration: 'none',
  ['&:hover,&:active']: {
    textDecoration: 'none'
  }
});

export const TileDiscountBanner = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  fontSize: '1.4rem',
  position: 'absolute',
  padding: theme.custom.spacing.small,
  backgroundColor: theme.custom.colors.group8.light,
  zIndex: '9',
  width: '100%',
  fontWeight: 'bold',
  textAlign: 'center'
}));
