import { TripTypes } from '@model/contentful';
import { Image } from '@model/common/image';
import { Price } from '@model/common';
import { HotelContent } from '@model/iceberg/hotel-content';

export interface TourDetails {
  name?: string;
  days?: number;
  excursionsCount?: number;
  image: string;
  mealsCount?: number;
  pace?: number;
  path: string;
  price?: Price;
  subTitle: string;
  tripType?: TripTypes;
  information?: TourInformation;
}

export interface TourDetailResponse {
  tours: Tours;
}

export type Tours = Array<TourDetails>;

export interface TourContent {
  name: string;
  days: number;
  allMealsIncluded: boolean;
  pace: number;
  mealsCount: number;
  excursionsCount: number;
  description: string;
  price: Price;
  type: TripTypes;
  subTitle: string;
  imageUrl: string;
  gallery: Array<Image>;
  highlights: Array<string>;
  extensions: Array<TourExtension>;
  hotels: Array<TourHotel>;
  fitHotels?: Array<HotelContent>;
  vessels: Array<TourVesselInfo>;
  information?: TourInformation;
  dossiers: Array<TourDossier>;
  reviews: Array<TourPostTripReview>;
  pricePanelNotes?: Array<string>;
}

export interface TourPostTripReview {
  name: string;
  date: string;
  review: string;
}

export interface TourDossier {
  name: string;
  url: string;
}

export interface TourExtension {
  name: string;
  days: number;
  price: Price;
  imageUrl: string;
  path: string;
}

export interface TourResponse<T> {
  data: T;
  errorCode?: number;
}

export interface TourHotel {
  name: string;
  address: string;
  resort: string;
  country: string;
  rating: number | null;
  ratingType?: string;
  telephone: string;
  description: string | null;
  featuredImage: string;
  gallery: Array<Image>;
  nights: number;
  deckPlans: Array<Image>;
  cabinPlan: Image;
  cabinPlanCaption: string | null;
  cabinInformation: string | null;
  departureDates: Array<string>;
}

export interface TourInformation {
  visa: string;
  insurance: string;
  payment: string;
  flight: string;
  health: string;
  accessibility: string;
  groupSize: string;
  tourGuide: string;
  currency: string;
  expenses: string;
  map?: Image;
}

export interface TourCheapest {
  year: number;
  month: string;
  price: Price;
}

export interface TourAvailabilityList {
  cheapest?: TourCheapest;
  availability: Array<TourAvailabilityResult>;
}

export interface TourAirport {
  name: string;
  code: string;
}

export interface TourAvailability {
  airports: Array<TourAirport>;
  reference?: string;
  departureDate: string;
  returnDate: string;
  price: Price;
  deepLink: string;
  seatsRemaining: number;
  callToBook: boolean;
  soldOut: boolean;
}

export interface TourAvailabilityResult {
  variation: TourVariation;
  results: TourAvailabilityYears;
  filters: TourAvailabilityFilters;
}

export interface TourVariation {
  id: number;
  title: string;
  path: string;
}

export interface TourAvailabilityYears {
  [year: string]: Array<TourAvailabilityHotelYear>;
}

export interface TourAvailabilityHotelYear {
  hotel: TourAvailabilityHotel;
  months: TourAvailabilityMonths;
}

export interface TourAvailabilityMonths {
  [month: string]: Array<TourAvailability>;
}

export interface TourAvailabilityHotel {
  name: string;
  rating: number;
  gallery: Array<string>;
  nights: number;
  description: string;
}

export interface TourAvailabilityFilters {
  airports: Array<TourAirport>;
  adults: Array<number>;
}

export interface TripTypeToursResponse {
  data: TripTypeTours;
}

export interface TripTypeTours {
  [location: string]: TripTypeTourLocation;
}

export interface TripTypeTourLocation {
  path: string;
  list: TripTypeTourRowsList;
}

export interface TripTypeTourRowsList {
  [tripRow: string]: TripTypeTourRowItem;
}

export interface TripTypeTourRowItem {
  path: string;
  fromPrice: Price;
}

export interface TourVesselInfo {
  name: string;
  description: string;
  rating: number;
}

export enum RatingType {
  STARS = 'STARS',
  ANCHORS = 'ANCHORS'
}
